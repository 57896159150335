import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './style.module.scss';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { testIds } from 'constants/test-constants';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_NAV_PAGE_CHANGE } from 'constants/events';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

// Import your Sass variables (use a preprocessor to inject the Sass variable value
// Define the Props interface
interface Props {
  pageNumber: number;
  totalPages: number;
  setPageNumber: (pageNumber: number) => void;
}

// Use the CSS variable directly since mui extenstion does not work properly with scss files.
const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.MuiPaginationItem-colorPrimary': {
            color: '#0C0F24',
            borderRadius: 8,
            borderColor: '#cecfd3',
            '&.Mui-selected': {
              backgroundColor: '#f3f3f4',
              borderColor: '#5a5a5a',
            },
            '&:hover': {
              backgroundColor: '#ffffff',
              borderColor: '#5a5a5a',
            },
            '&:focus': {
              backgroundColor: '#f3f3f4',
              borderColor: '#5a5a5a',
            },
          },
        },
      },
    },
  },
});

export default function PaginationNav({ pageNumber, totalPages, setPageNumber }: Props) {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  const small = useMediaQuery('(max-width:600px)');
  if (totalPages <= 1) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    trackEvent(GTM_CLICK_NAV_PAGE_CHANGE);
    setPageNumber(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.component} data-testid={testIds.pageNavigation}>
        <Stack>
          <Pagination
            page={pageNumber}
            count={totalPages}
            onChange={handleChange}
            shape="rounded"
            color={isExpTheme ? 'primary' : 'standard'}
            siblingCount={small ? 0 : 1}
            renderItem={item => (
              <PaginationItem
                components={{ previous: ArrowBackIosNewIcon, next: ArrowForwardIosIcon }}
                {...item}
              />
            )}
          />
        </Stack>
      </div>
    </ThemeProvider>
  );
}
