import { useEffect, useState } from 'react';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import styles from './style.module.scss';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AGENT_PAGE_SUGGESTED_LOCATIONS, GTM_CLICK_AGENT_PAGE_SUGGESTED_NAMES } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';

export interface SuggestionDropdownProps {
  suggestions: string[];
  type: string;
  onSelect: (suggestion: string) => void;
  doShow: boolean;
}

export default function SuggestionDropdown({ suggestions, type, onSelect, doShow = false }: SuggestionDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(doShow);

  const outsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => setShowDropdown(false));

  useEffect(() => {
    setShowDropdown(doShow);
  }, [doShow]);

  const handleClickEvent = () => {
    if (type == 'location') {
      trackEvent(GTM_CLICK_AGENT_PAGE_SUGGESTED_LOCATIONS);
    } else if (type == 'name') {
      trackEvent(GTM_CLICK_AGENT_PAGE_SUGGESTED_NAMES);
    }
  };

  if (!showDropdown) return null;
  return (
    <div className={styles['suggestions-container']} ref={outsideClickHandler}>
      {suggestions.map((s, index) => (
        <div 
          onClick={() => {
            handleClickEvent();
            onSelect(s);}} 
          key={index} 
          className={styles['suggestion']} data-testid={agentSearchIds.agentSearchDropdownOption}>{s}</div>
      ))}
    </div>
  );
}