import React, { useState, useEffect } from 'react';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { SuggestionDropdown } from 'components/agent-search';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import NextImage from 'next/image';
import styles from './style.module.scss';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AGENT_PAGE_LOCATION_SEARCH_BAR, GTM_CLICK_AGENT_PAGE_EDIT_COUNTRY } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';

interface InputWithCountrySelectProps {
  onCountryChange: (countryCode: CountryCodeList) => void;
  country: CountryCodeList;
  onLocationSelect: (location: string) => void;
  locationQuery: string;
  updateLocationQuery: (query: string) => void;
  allowCountryChange?: boolean;
}

export default function InputWithCountrySelect({ onCountryChange, country, onLocationSelect, locationQuery, updateLocationQuery, allowCountryChange = true }: InputWithCountrySelectProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [locations, setLocations] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const outsideClickHandler = useOutsideClickHandler<HTMLDivElement>(() => setShowDropdown(false));

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await AgentActions.locationSuggestions(locationQuery, country);
      if (!(data instanceof Error)) {
        if (data) {
          setLocations(data);
        } else {
          setLocations([]);
        }
      }
    };

    if (locationQuery.length > 0) fetchLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationQuery]);

  const countries = [
    { code: CountryCodeList.UNITED_STATES, name: 'United States', flagUrl: '/next/assets/images/us-flag.svg' },
    { code: CountryCodeList.CANADA, name: 'Canada', flagUrl: '/next/assets/images/ca-flag.svg' },
  ];

  const handleCountrySelect = (countryCode: CountryCodeList) => {
    onCountryChange(countryCode);
    setShowDropdown(false);
  };

  const handlesLocationQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateLocationQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSelectLocation = (l: string) => {
    onLocationSelect(l);
    updateLocationQuery(l);
    setLocations([]);
    setShowSuggestions(false);
  };

  const handleClickEvent = () => {
    trackEvent(GTM_CLICK_AGENT_PAGE_EDIT_COUNTRY);
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={styles['input-wrapper']}>
      <div className={styles.dropdown} ref={outsideClickHandler}>
        {allowCountryChange ? (
          <div onClick={handleClickEvent} className={styles['drop-button']} data-testid={agentSearchIds.countryDropdown}>
            {country === CountryCodeList.UNITED_STATES
              ? <NextImage width={30} height={30} src={countries[0].flagUrl} alt="Canada Flag" />
              : <NextImage width={30} height={30} src={countries[1].flagUrl} alt="Canada Flag" />
            }
            <ArrowDropDown style={{ padding: 0, margin: 0 }} />
          </div>
        ) : (
          // Make default cursor
          <div className={styles['drop-button']} style={{ cursor: 'default' }} data-testid={agentSearchIds.countryDropdown}>
            {country === CountryCodeList.UNITED_STATES
              ? <NextImage width={35} height={35} src={countries[0].flagUrl} alt="Canada Flag" />
              : <NextImage width={35} height={35} src={countries[1].flagUrl} alt="Canada Flag" />
            }
          </div>
        )}
        {showDropdown && (
          <div className={styles['dropdown-content']}>
            {countries.map((country: any) => (
              <div className={styles['drop-option']} key={country.code} onClick={() => handleCountrySelect(country.code)} data-testid={agentSearchIds.countryDropdownOption}>
                <NextImage width={25} height={25} src={country.flagUrl} alt={`${country.name} Flag`} />
              </div>
            ))}
          </div>
        )}
        {/* location suggestion dropdown */}
        <SuggestionDropdown suggestions={locations} type={'location'} onSelect={handleSelectLocation} doShow={locations.length > 0 && showSuggestions} />
      </div>
      <input type="text" placeholder="Location" data-testid={agentSearchIds.locationInput} onInput={handlesLocationQueryChange} value={locationQuery} onClick={() => trackEvent(GTM_CLICK_AGENT_PAGE_LOCATION_SEARCH_BAR)} />
    </div>
  );
}
