import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import Search from '@mui/icons-material/Search';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import { SuggestionDropdown } from 'components/agent-search';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_AGENT_PAGE_NAME_SEARCH_BAR } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';

interface InputWithSearchIconProps {
  onSelectName: (query: string) => void;
  country: CountryCodeList;
  nameQuery: string;
  updateNameQuery: (query: string) => void;
}

export default function InputWithSearchIcon({ onSelectName, country, nameQuery, updateNameQuery }: InputWithSearchIconProps) {
  const [names, setNames] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const fetchNames = async () => {
      const data = await AgentActions.nameSuggestions(nameQuery, country);
      if (!(data instanceof Error)) {
        if (data) {
          setNames(data);
        } else {
          setNames([]);
        }
      }
    };

    if (nameQuery.length > 0) fetchNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameQuery]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateNameQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSelectName = (n: string) => {
    updateNameQuery(n);
    onSelectName(n);
    setNames([]);
    setShowSuggestions(false);
  };

  return (
    <div className={styles['input-wrapper']}>
      <div className={styles.dropdown}>
        <div className={styles['search-button']} data-testid={agentSearchIds.agentSearchIcon}>
          <Search style={{ padding: 0, margin: 0 }} />
        </div>
        {/* name suggestion dropdown */}
        <SuggestionDropdown
          suggestions={names}
          type={'name'}
          doShow={names.length > 0 && showSuggestions}
          onSelect={handleSelectName}
        />
      </div>
      <input type="text" placeholder='Name' data-testid={agentSearchIds.agentNameInput} onInput={handleQueryChange} value={nameQuery} onClick={() => trackEvent(GTM_CLICK_AGENT_PAGE_NAME_SEARCH_BAR)}/>
    </div>
  );
}
