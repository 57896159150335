declare global {
  interface Window {
    grecaptcha: any;
  }
}

import { useState, useEffect } from 'react';
import type { AgentList } from 'utils/agent-endpoint';
import InputWithCountrySelect from './input-with-country-select/InputWithCountrySelect';
import InputWithSearchIcon from './input-with-search-icon/InputWithSearchIcon';
import PaginationNav from 'components/control/pagination-nav';
import LoadingSpinner from 'components/loading-spinner';
import * as AgentActions from 'utils/agent-endpoint';
import { CountryCodeList } from 'types/countries';
import { PAGE_SIZE, AgentType } from 'utils/agent-endpoint';
import AgentCard from './agent-card/AgentCard';
import { useRouter } from 'next/router';
import NextImage from 'next/image';
import Script from 'next/script';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_VISIT_AGENT_SEARCH_PAGE } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { isClientSide } from 'utils/host-config';

import styles from './style.module.scss';
import { HeadDataType } from 'components/head-data';

/**
 * Agent search page props
 * @param {AgentList[]} featureAgents - List of featured agents initially displayed on the page
 * @param {CountryCodeList} initialCountry - If URL query contained country code or we get it from site location
 * @param {string} sessionId - sessionId used for agent pagination performance
 * @param {HeadDataType} headerData - Header data for the page
 * @param {AgentType} agentType - Type of search page (Agent or Commercial)
 */
export interface AgentSearchProps {
  featureAgents: AgentList[] | null;
  initialCountry: CountryCodeList;
  sessionId: string;
  headerData: HeadDataType;
  agentType: AgentType;
}

const AgentSearch = ({ initialCountry, sessionId, agentType = AgentType.RESIDENTIAL }: AgentSearchProps) => {
  const router = useRouter();

  const initialPage = parseInt(router.query.page as string, 10) || 1;
  const initialLocation = router.query.location ? decodeURIComponent(router.query.location as string) : '';
  const isCommercial = agentType === AgentType.COMMERCIAL;
  const [featuredAgents, setFeaturedAgents] = useState<AgentList[]>([]);
  const [isFeatured, setIsFeatured] = useState(!initialLocation || isCommercial);
  const [scriptLoaded, setScriptLoaded] = useState(isClientSide() && typeof window.grecaptcha !== 'undefined');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(initialPage);
  // Selected location when user selects from dropdown
  const [selectedLocation, setSelectedLocation] = useState(initialLocation);
  // Location query for location input
  const [locationQuery, setLocationQuery] = useState(initialLocation);
  const [agents, setAgents] = useState<AgentList[]>([]);

  const [country, setCountry] = useState(initialCountry);
  const [selectedName, setSelectedName] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const agentTypeName = agentType === AgentType.COMMERCIAL ? 'Commercial Advisor' : 'eXp Agent';

  // When country changes, reset all queries and fetch featured agents
  useEffect(() => {
    const fetchFeaturedAgents = async () => {
      if (isCommercial) return;
      setIsLoading(true);
      resetQueries();
      const response = await AgentActions.featuredAgents(country);
      if (!(response instanceof Error)) {
        setFeaturedAgents(response.agents);
        setIsFeatured(true);
      }
      setIsLoading(false);
    };

    if ((!selectedLocation || !isCommercial) && scriptLoaded) fetchFeaturedAgents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, scriptLoaded]);

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);

      const data = await AgentActions.searchAgents({
        name: selectedName,
        location: selectedLocation,
        country,
        page,
        session_id: sessionId,
        agentType,
      });
      if (!(data instanceof Error)) {
        setAgents(data.agents);
        setCount(data.count);
      }
      // If search happened remove featured agents mode
      if (isFeatured) setIsFeatured(false);
      setIsLoading(false);
    };

    if (scriptLoaded && (selectedName.length > 0 || selectedLocation.length > 0 || isCommercial)) {
      fetchAgents().then(() => {
        updatePathQuery();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedName, selectedLocation, page, scriptLoaded]);

  useEffect(() => {
    if (isFeatured && !isCommercial) trackEvent(GTM_VISIT_AGENT_SEARCH_PAGE);
  });

  const handleSelectLocation = (location: any) => {
    setSelectedName('');
    setNameQuery('');
    setPage(1);
    setSelectedLocation(location);
    setLocationQuery(location);
  };

  const handleSelectName = (name: string) => {
    setSelectedLocation('');
    setLocationQuery('');
    setPage(1);
    setSelectedName(name);
  };

  const resetQueries = () => {
    setSelectedLocation('');
    setLocationQuery('');
    setSelectedName('');
    setNameQuery('');
  };

  const updatePathQuery = () => {
    const params = {
      country,
      location: encodeURIComponent(selectedLocation),
      page: page.toString(),
    };
    const queryString = new URLSearchParams(params).toString();
    if (window) window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
  };

  const updateCountry = (country: CountryCodeList) => {
    setCountry(country);
  };

  const handleUpdatePageNumber = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const renderCount = (): string => {
    if (isFeatured && agentType === AgentType.COMMERCIAL) return `Featured ${agentTypeName}s`;
    if (isFeatured) return `16 Featured ${country} eXp Agents`;
    if (selectedLocation.length > 0 ) return `${count} ${selectedLocation.split(',')[0]} ${agentTypeName}${count !== 1 ? 's' : ''}`;
    return `${count} ${country} ${agentTypeName}${count === 1 ? 's' : ''}`;
  };

  return (
    <div className={styles.component}>
      <div className={`${styles['agent-header']}`}>
        <div className={`${styles['exp-header-banner']}`}>
          <NextImage
            key="exp-banner-white-no-text-1"
            fill
            src="/next/assets/images/agent-search/exp-banner-white-no-text.png"
            alt="eXp Realty Logo"
            priority={true}
          />
        </div>
        <h1 className={styles['agent-header-title']} data-testid={agentSearchIds.agentSearchHeader}>
          Search All {country === CountryCodeList.CANADA ? 'Canada' : 'US'} {agentTypeName}s
        </h1>

        <div className={styles.inputs}>
          <InputWithCountrySelect
            onCountryChange={updateCountry}
            country={country}
            onLocationSelect={handleSelectLocation}
            locationQuery={locationQuery}
            updateLocationQuery={(query: string) => setLocationQuery(query)}
            allowCountryChange={!isCommercial}
          />

          <InputWithSearchIcon
            onSelectName={handleSelectName}
            country={country}
            nameQuery={nameQuery}
            updateNameQuery={(query: string) => setNameQuery(query)}
          />
        </div>
      </div>

      {(!scriptLoaded || isLoading) && (
        <div style={{ height: '700px' }}>
          <LoadingSpinner />
        </div>
      )}

      {(scriptLoaded && !isLoading) && (
        <>
          <p className={styles['result-count']} data-testid={agentSearchIds.agentsResultCount}>{renderCount()}</p>
          <div className={styles['search-results-container']}>
            {featuredAgents && featuredAgents.length > 0 && isFeatured && (
              <>
                {featuredAgents.map((agent, index) => (
                  <AgentCard key={index} agent={agent} />
                ))}
              </>
            )}

            {(agents && Array.isArray(agents)) ?
              agents.map((agent, index) => (
                <AgentCard key={index} agent={agent} />
              ))
              : (
                <div className={styles['no-results']} data-testid={agentSearchIds.agentNoResultsMessage}>
                  <NextImage
                    src="/next/assets/images/agent-search/no-agent-results.png"
                    alt="No results found"
                    width={237}
                    height={176}
                  />
                  <h1>We couldn’t find any matches for your search.</h1>
                  <p>Check for misspelled words or try a different search term.</p>
                </div>
              )}
          </div>
        </>
      )}
      <PaginationNav
        pageNumber={page}
        totalPages={Math.ceil(count / PAGE_SIZE) || 1}
        setPageNumber={handleUpdatePageNumber}
      />
      {!scriptLoaded &&
        <Script src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
          onLoad={() => setScriptLoaded(true)}
        />
      }
    </div>
  );
};

export default AgentSearch;
